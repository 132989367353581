// Set current device width as a javascript variable. Availible alternatives: xs, sm, md, lg.
// See _breakpoints.scss for more info.
// Use like this: console.log(breakpoint.value)

var breakpoint = {};
(function ($) {
  breakpoint.refreshValue = function () {
    this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
  };
  var updateBreakpointValueOnDebouncedResize = debounce(function () {
    // Throttles window resize events.
    breakpoint.refreshValue();
  }, 250);
  window.addEventListener('resize', updateBreakpointValueOnDebouncedResize);
  breakpoint.refreshValue();
})(jQuery);

// lazy load callback finish
function ll_finish(args) {
  if (typeof ll_finish_impl === "function") {
    ll_finish_impl(args); // To implement where needed
  }
}
function showEDometicSpinner() {
  var theSpinner = document.querySelector(".edometic-spinner");
  if (theSpinner) {
    theSpinner.style.display = "block";
  }
}
function hideEDometicSpinner() {
  var theSpinner = document.querySelector(".edometic-spinner");
  if (theSpinner) {
    theSpinner.style.display = "none";
  }
}