function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
;
function absoluteUrl(relativeurl) {
  var root = location.protocol + '//' + location.host;
  return root + relativeurl;
}
$("[data-smooth-scroll]").click(function (e) {
  var targetHash = $(this).attr("href");
  var $target = $(targetHash);
  var scrollOffset = $(this).data("scroll-offset");
  $('html, body').animate({
    scrollTop: $target.offset().top
  }, {
    // Set the duration long enough to allow time
    // to lazy load the elements.
    duration: 2000,
    // At each animation step, check whether the target has moved.
    step: function step(now, fx) {
      // Where is the target now located on the page?
      // i.e. its location will change as images etc. are lazy loaded
      // subtracting the vertical offset of the element
      var newOffset = $target.offset().top - scrollOffset;

      // If where we were originally planning to scroll to is not
      // the same as the new offset (newOffset) then change where
      // the animation is scrolling to (fx.end).
      if (fx.end !== newOffset) {
        fx.end = newOffset;
      }
    }
  });
});
function scrollToElement(el) {
  if (!isElementInViewport(el)) {
    var elOffset = $(el).offset().top,
      menuHeight = $(".navigationMainComponent").outerHeight(),
      scrollPosition = elOffset - menuHeight;
    $('html, body').animate({
      scrollTop: scrollPosition * 0.9 + "px"
    }, 500);
  }
}
function isElementInViewport(el) {
  var stickyTopHeight = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }
  var rect = el.getBoundingClientRect();
  return rect.top - stickyTopHeight >= 0 &&
  //subtract navbarheight from viewport to enable scrolling to elements hidden beneth navbar.
  rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
  rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */;
}

/**
 * /
 * @param {Element} form //the form 
 */
function scrollToFirstInvalidElement(form) {
  var navbarHeight = $(".navigationMainComponent").outerHeight() + 50;

  // listen for `invalid` events on all form inputs
  $(form).find(':input').on('invalid', function (event) {
    var input = $(this);

    // the first invalid element in the form
    var first = form.find(':invalid').first();

    // only handle if this is the first invalid input
    if (input[0] === first[0]) {
      if (!isElementInViewport(input[0], navbarHeight)) {
        // height of the nav bar plus some padding

        // the position to scroll to (accounting for the navbar)
        var elementOffset = input.offset().top - navbarHeight;
        // note: avoid using animate, as it prevents the validation message displaying correctly
        $('html,body').scrollTop(elementOffset);
      }
    }
  });
}