(function ($) {
  var protocol = location.protocol,
    host = location.hostname;

  // External links are the ones starting with http(s)://, and where the target host differs from the current host.
  $('a[href^="http://"], a[href^="https://"]').not('[href^="' + protocol + '//' + host + '"],[data-skip-tracking=true]').on('click', function (event) {
    if (window.ga && ga.create) {
      // Check if analytics is loaded
      var $this = $(this),
        href = $this.attr("href");
      if ($this.attr("target") === "_blank") {
        if (gtag) {
          gtag('event', href, {
            'event_category': 'outbound',
            'event_action': 'link'
          });
        }
      } else {
        // Since the page will be torn down and all JS will be destroyed when clicking on a link, we have to prevent the default action and redirect the user in the callback instead.
        if (gtag) {
          event.preventDefault();
          gtag('event', href, {
            'event_category': 'outbound',
            'event_action': 'link',
            'event_callback': loadPage(href)
          });
        }
      }
    }
  });
  function loadPage(href) {
    console.log("External link tracking executed.");
    window.location.assign(href);
  }
})(jQuery);