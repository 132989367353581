(function ($) {
  // Enabling popovers
  $("[data-toggle=popover]").popover({
    html: true,
    trigger: 'focus',
    container: 'body',
    title: function title() {
      return $(this).next().find(".u-popoverTitle").html();
    },
    content: function content() {
      return $(this).next().find(".u-popoverBody").html();
    }
  });
  $('[data-toggle="popover2"]').popover();
})(jQuery);